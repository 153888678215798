import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import {
  Loader,
  DefaultLayout,
  LoginPage,
  NotFoundPage,
  OrdersPage,
  HomePage,
  OrderPage,
  UsersPage,
  UserPage,
  PromoCodePage,
  PromoCodeCreateOrEditPage,
  HomepagePage,
  UploadsPage,
  GroupsListPage,
  GroupsCreatePage,
  GroupsEditPage,
  DictionaryPage,
  MediaEditPage,
  MediaCreatePage,
  MediaListPage,
  MenuUpdate,
  Brands,
  Brand,
  Statistics,
  CatalogInfo,
  CatalogInfoCreate,
  CatalogInfoByAlias,
  AbandonedCart,
} from "common";
import { NotificationCreate, NotificationId, NotificationList } from "common/pages/notification";

import { authSlice, useAppDispatch, useAppSelector, getProfile } from "store";

import "styles/core.scss";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { initialAuth } = authSlice.actions;
  const { isInitial, account } = useAppSelector(state => state.auth);

  useEffect(() => {
    dispatch(getProfile()).then(() => { dispatch(initialAuth()) });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isInitial && !account) {
      navigate("/login");
    }
  }, [isInitial, account]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isInitial) {
    return (
      <div className="app">
        <Loader className="app__loader" />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<DefaultLayout children={<HomePage />} />} />
      <Route path="/orders" element={<DefaultLayout children={<OrdersPage />} />} />
      <Route path="/order/:id" element={<DefaultLayout children={<OrderPage />} />} />
      <Route path="/users" element={<DefaultLayout children={<UsersPage />} />} />
      <Route path="/user/:id" element={<DefaultLayout children={<UserPage />} />} />
      <Route path="/promo-code/create" element={<DefaultLayout children={<PromoCodeCreateOrEditPage />} />} />
      <Route path="/promo-code/edit/:id" element={<DefaultLayout children={<PromoCodeCreateOrEditPage />} />} />
      <Route path="/promo-code" element={<DefaultLayout children={<PromoCodePage />} />} />
      <Route path="/homepage" element={<DefaultLayout children={<HomepagePage />} />} />
      <Route path="/uploads" element={<DefaultLayout children={<UploadsPage />} />} />
      <Route path="/groups" element={<DefaultLayout children={<GroupsListPage />} />} />
      <Route path="/groups/create" element={<DefaultLayout children={<GroupsCreatePage />} />} />
      <Route path="/groups/edit/:id" element={<DefaultLayout children={<GroupsEditPage />} />} />
      <Route path="/dictionary" element={<DefaultLayout children={<DictionaryPage />} />} />
      <Route path="/media" element={<DefaultLayout children={<MediaListPage />} />} />
      <Route path="/media/create" element={<DefaultLayout children={<MediaCreatePage />} />} />
      <Route path="/media/:id/:lang" element={<DefaultLayout children={<MediaEditPage />} />} />
      <Route path="/menu" element={<DefaultLayout children={<MenuUpdate />} />} />
      <Route path="/menu" element={<DefaultLayout children={<MenuUpdate />} />} />
      <Route path="/brands" element={<DefaultLayout children={<Brands />} />} />
      <Route path="/brands/:id/" element={<DefaultLayout children={<Brand />} />} />
      <Route path="/statistics" element={<DefaultLayout children={<Statistics />} />} />
      <Route path="/catalog-info" element={<DefaultLayout children={<CatalogInfo />} />} />
      <Route path="/catalog-info/create" element={<DefaultLayout children={<CatalogInfoCreate />} />} />
      <Route path="/catalog-info/:alias" element={<DefaultLayout children={<CatalogInfoByAlias />} />} />
      <Route path="/notification" element={<DefaultLayout children={<NotificationList />} />} />
      <Route path="/notification/create" element={<DefaultLayout children={<NotificationCreate />} />} />
      <Route path="/notification/:id" element={<DefaultLayout children={<NotificationId />} />} />
      <Route path="/abandoned-cart" element={<DefaultLayout children={<AbandonedCart />} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
