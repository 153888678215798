import { useNavigate } from 'react-router-dom';
import queryString from "query-string";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { CLIENT_URL, IMAGES_PATH, SERVER_URL } from 'config';
import { UserAbandonedCart } from './types';
import './abandoned-cart.scss';
import { InputWithLabel, Pagination, Selector } from 'common/components';

export const AbandonedCart = () => {
  const [abandonedCart, setAbandonedCart] = useState<null | UserAbandonedCart[]>(null);
  const [expandedUser, setExpandedUser] = useState<any>(null);
  const [allpage, setAllPage] = useState<null | number>(null);
  const [isOld, setIsOld] = useState(false);
  const [currentLang, setCurrentLang] = useState<"en-EN" | "ru-RU">('ru-RU');

  const page = queryString.parse(window.location.search).page as string;
  const limit = 36;

  const navigate = useNavigate();

  useEffect(() => {
    fetchAbandonedCart({
      page: page ?? 1,
      limit,
      is_old: isOld.toString(),
    });
  }, [page, isOld, currentLang]);

  const fetchAbandonedCart = async (params?: Record<string, string | number>) => { // ?page=2&limit=1
    const config = {
      url: SERVER_URL + "/admin/abandoned-users-carts",
      method: "get",
      headers: { "Accept-Language": currentLang },
      withCredentials: true,
      params,
    };

    try {
      const { data } = await axios.request(config);
      setAllPage(data.pages);
      setAbandonedCart(data.items);
    }
    catch (error) {
      console.log('fetchAbandonedCart error', error);
      return setAbandonedCart([]);
    }
  };

  const toggleIsOld = () => { setIsOld(prev => !prev) }

  if (!abandonedCart) {
    return (
      <div>
        Loading...
      </div>
    );
  }

  return (
    <div className='abandoned-cart-table'>
      <div className='abandoned-cart-checkbox'>
        <InputWithLabel type="checkbox" label="Показать только старые корзины" checked={isOld} onChange={toggleIsOld} />      
        <Selector
          label="Cart Lang"
          currentValue={currentLang}
          values={["en-EN", "ru-RU"]}
          onChange={(e) => { setCurrentLang((e.target as any).value) }}
        />  
      </div>
      <table border={1} cellPadding={5} cellSpacing={0}>
        <thead>
          <tr>
            <th>ID</th>
            <th>ФИО</th>
            <th>Email</th>
            <th>Телефон</th>
            <th>Последнее использование</th>
            <th>Товары</th>
          </tr>
        </thead>
        <tbody>
          {abandonedCart.map((user) => (
            <>
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{`${user.last_name} ${user.first_name} ${user.third_name}`}</td>
                <td>{user.email}</td>
                <td>{user.phone_number}</td>
                <td>{new Date(user.date_last_time_use_cart).toLocaleDateString()}</td>
                <td style={{ padding: '0' }}>
                  <div
                    onClick={() => setExpandedUser(expandedUser === user.id ? null : user.id)}
                    style={{ cursor: 'pointer', padding: '5px'}}
                  >
                    {expandedUser === user.id ? "Скрыть" : "Показать"}
                  </div>
                </td>
              </tr>
              {expandedUser === user.id && (
                <tr>
                  <td colSpan={6} className='inner-table'>
                    <table border={1} cellPadding={5} cellSpacing={0}>
                      <thead>
                        <tr>
                          <th>Картинка</th>
                          <th>Название</th>
                          <th>Бренд</th>
                          <th>Цена</th>
                          <th>Размер</th>
                        </tr>
                      </thead>
                      <tbody>
                        {user.products.map((product) => (
                          <tr key={product.id} className='links'>
                            <td style={{ padding: 0 }}>
                              <img
                                src={`${IMAGES_PATH}/${product.id}/${product.images[0].min}`}
                                alt="banner"
                                style={{ width: "80px", height: '80px', objectFit: "cover", fontSize: 0 }}
                              />
                            </td>
                            <td>
                              <a href={`${CLIENT_URL}/product/${product.id}`} target='_blank'>
                                {product.name}
                              </a>
                            </td>
                            <td>
                              <a href={`${CLIENT_URL}/product/${product.id}`} target='_blank'>
                                {product.brand.name}
                              </a>
                            </td>
                            <td>
                              <a href={`${CLIENT_URL}/product/${product.id}`} target='_blank'>
                                {`${product.price} ${product.currency}`}
                              </a>
                            </td>
                            <td>
                              <a href={`${CLIENT_URL}/product/${product.id}`} target='_blank'>
                                {product.size}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>

      {allpage ? <>
        <Pagination
          totalCount={allpage}
          currentPage={page ? +page : 1}
          pageSize={limit}
          onPageChange={(selected: number) => {
            const tempQuery = queryString.parse(window.location.search);
            tempQuery.page = "" + selected;
            const tempQueryAsString = queryString.stringify(tempQuery);
            console.log(window.location.origin)
            navigate("?" + tempQueryAsString);
          }}
          className="pagination"
        />
      </> : null}


    </div>
  );
};
