import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import { fetchLogout, useAppDispatch, useAppSelector } from "store";

import "./menu.scss";

export function Menu() {
  const dispatch = useAppDispatch();

  const [isShowMenu, setIsShowMenu] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLDivElement>(null);
  
  const { account, status: { error, isLoading } } = useAppSelector(state => state.auth);

  useEffect(() => {
    if (error) {
      alert(error.message);
    }
  }, [error]);

  const handleClickOutsideMenu = (event: any) => {
    if (!wrapperRef.current!.contains(event.target)) {
      if (!menuButtonRef.current!.contains(event.target)) {
        setIsShowMenu(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => { document.removeEventListener("mousedown", handleClickOutsideMenu) };
  }, []);

  const logoutHandler = () => {
    dispatch(fetchLogout());
  };

  const menuSwitcher = () => {
    setIsShowMenu(!isShowMenu);
  };

  return (
    <div className={cn("menu", isShowMenu && "show")}>
      <div className="wrapper">
        <div className="top-menu">
          <div className="links-menu-switcher" onClick={menuSwitcher} ref={menuButtonRef}>
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em">
              <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z" />
            </svg>
          </div>
          <div className="username">{account && <>{account.firstName} {account.lastName}</>}</div>
          <div className={cn("exit", isLoading && "disabled")} onClick={logoutHandler}>
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M5 22a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3h-2V4H6v16h12v-2h2v3a1 1 0 0 1-1 1H5zm13-6v-3h-7v-2h7V8l5 4-5 4z" />
            </svg>
          </div>
        </div>
        <div className={cn("links", isShowMenu && "links-open")} ref={wrapperRef}>
          <Link to="/orders" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Orders</Link>
          <Link to="/users" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Users</Link>
          <Link to="/homepage" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Homepage</Link>
          <Link to="/uploads" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Uploads</Link>
          <Link to="/promo-code" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Promo code</Link>
          <Link to="/dictionary" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Dictionary</Link>
          <Link to="/menu" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Menu</Link>
          <Link to="/catalog-info" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Catalog info</Link>
          <Link to="/brands" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Brands</Link>
          <Link to="/carriers" className={cn("link", true && "disabled")} onClick={menuSwitcher}>Carriers</Link>
          <Link to="/groups" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Groups</Link>
          <Link to="/media" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Media</Link>
          <Link to="/statistics" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Statistics</Link>
          <Link to="/notification" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Notification</Link>
          <Link to="/abandoned-cart" className={cn("link", false && "disabled")} onClick={menuSwitcher}>Abandoned Cart</Link>
        </div>
      </div>
    </div>
  );
}
